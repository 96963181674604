<template>
  <div class="app">
    <router-view />
  </div>
</template>
<script>
import { mapMutations } from 'vuex';

export default {
  data() {
    return {};
  },
  methods: {
    ...mapMutations(['onLoad', 'outLoad']),
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      // eslint-disable-next-line default-case
      switch (mutation.type) {
        case 'core/SUCCESS_MESSAGE':
          this.$toastr.s(state.core.success, 'Successfully');
          this.outLoad();
          break;
        case 'core/ERROR_MESSAGE':
          this.$toastr.e(state.core.error, 'Failed');
          this.outLoad();
          break;
        case 'auth/LOGOUT_SUCCESS':
          break;
      }
    });
  },
};
</script>
<style>
html {
  scroll-behavior: smooth;
}
.particles {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
</style>
