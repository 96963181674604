<template>
  <div id="header">
    <div class="bg-image p-2 text-center shadow-1-strong text-white">
      <nav class="navbar navbar-expand-xl">
        <div class="container-fluid px-5">
          <div class="logo">
            <a class="navbar-brand" href="#">
              <img
                class="logo-header"
                src="~@/assets/images/Header/Artboard 10.png"
                alt=""
                width="120"
                height="100"
              />
            </a>
          </div>
          <button
            id="button-collapse"
            class="navbar-toggler custom-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse justify-content-end" id="navbarNav" v-if="showMenu">
            <ul class="navbar-nav">
              <li class="nav-item" @click="active = 1" aria-expanded="false">
                <a class="nav-link" :class="active === 1 ? 'active' : ''" href="#">Home</a>
              </li>
              <li class="nav-item" @click="active = 2">
                <a class="nav-link" :class="active === 2 ? 'active' : ''" href="#about">About</a>
              </li>
              <li class="nav-item" @click="active = 3">
                <a class="nav-link" :class="active === 3 ? 'active' : ''" href="#base">Base</a>
              </li>
              <li class="nav-item" @click="active = 4">
                <a class="nav-link" :class="active === 4 ? 'active' : ''" href="#benefit"
                  >Benefit</a
                >
              </li>
              <li class="nav-item" @click="active = 5">
                <a class="nav-link" :class="active === 5 ? 'active' : ''" href="#token"
                  >Tokenomic</a
                >
              </li>
              <li class="nav-item" @click="active = 6">
                <a class="nav-link" :class="active === 6 ? 'active' : ''" href="#roadmap"
                  >Roadmap</a
                >
              </li>
              <li class="nav-item" @click="active = 7">
                <a class="nav-link" :class="active === 7 ? 'active' : ''" href="#partner"
                  >Partner</a
                >
              </li>
              <li class="nav-item" @click="active = 8">
                <a class="nav-link" :class="active === 8 ? 'active' : ''" href="#community"
                  >Community</a
                >
              </li>
              <li class="nav-item">
                <a class="nav-link" href="https://system.heroland.app" target="_blank">Launch App</a>
              </li>
              <li class="nav-item" v-if="false">
                <a class="nav-link"
                  ><img
                    src="~@/assets/images/Header/Artboard 12.png"
                    alt=""
                    width="50px"
                    height="50px"
                /></a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      width: 1920,
      active: 1,
      showMenu: true,
    };
  },
  methods: {
    handleResize() {
      this.width = window.innerWidth;
      // if (window.innerWidth <= 1024) {
      //   this.showMenu = false;
      // } else {
      //   this.showMenu = true;
      // }
    },
    changeLanguage(lang) {
      this.currentLanguage = lang;
      this.$i18n.locale = lang;
    },
  },
  async created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  async mounted() {
    // if (window.innerWidth <= 1024) {
    //   this.showMenu = false;
    // } else {
    //   this.showMenu = true;
    // }
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  watch: {
    $route(to, from) {
      console.log(to);
      console.log(from);
      if (to.hash !== from.hash) {
        if (this.width <= 1200) {
          const btn = document.getElementById('navbarNav');
          btn.classList.remove('show');
        }
      }
    },
  },
};
</script>

<style lang="scss">
#header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  font-family: 'Orbitron', sans-serif;
}
.logo {
  margin-left: 70px;
  @media (max-width: 1699px) {
    margin-left: 0px;
  }
}
.logo-header {
  animation: logo 1.2s linear infinite;
  transition: 0.5s;
  @media (max-width: 1499px) {
    width: auto;
    height: 80px;
  }
  @media (max-width: 991px) {
    width: auto;
    height: 60px;
  }
}
.logo-header:hover {
      transform: scale(1.1);
    }
@keyframes logo {
  25% {
    transform: rotateY(0);
  }
  50% {
    transform: rotateY(90deg);
  }
  75% {
    transform: rotateY(0);
  }
}
.navbar-expand-xl {
  .container-fluid {
    @media (max-width: 1399px) {
      padding: 0px 15px !important;
    }
  }
}
.bg-image {
  background-image: url('~@/assets/images/Header/Artboard 11.png');
}
.container-fluid {
  padding-left: 0;
  padding-right: 0;
}
.navbar-collapse {
}
.nav-item {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  font-weight: bold;
  gap: 1rem;
  margin: 0px 15px;
  @media (max-width: 1799px) {
    font-size: 20px;
  }
  @media (max-width: 1599px) {
    font-size: 18px;
    margin: 0px 10px;
  }
  @media (max-width: 1399px) {
    margin: 0px 5px;
  }
  @media (max-width: 1299px) {
    font-size: 17px;
    margin: 0px 0px;
  }
}

.nav-link {
  color: #a0d1a0;
  text-decoration: none;
}

.active {
  color: rgb(52, 248, 52);
}

.nav-link:hover {
  color: rgb(6, 248, 6);
}

.custom-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.custom-toggler.navbar-toggler {
  border-color: rgb(255, 255, 255);
}
</style>
