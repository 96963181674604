<template>
  <div class="eggsbook">
    <Header />
    <loader />
    <div class="content-page">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import Header from './Header.vue';
import loader from '@/components/Loader.vue';

export default {
  components: {
    Header,
    loader,
  },
  data() {
    return {
      addressCurrent: '',
      isConnected: false,
    };
  },
  mounted() {
  },
  methods: {},
};
</script>

<style lang="scss">
.eggsbook {
  overflow-x: hidden;
}
.content-page {
  margin-top: 140px;
  @media (max-width: 1499px) {
    margin-top: 120px;
  }
  @media (max-width: 991px) {
    margin-top: 100px;
  }
}
</style>
